import * as React from "react";

function HandShake(props) {
  return (
    <svg viewBox="0 0 1035.04 737.45" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-5{fill:#de8e68}.prefix__cls-11,.prefix__cls-6,.prefix__cls-9{fill:none}.prefix__cls-6{stroke:#5b5b5b;stroke-width:1.62px}.prefix__cls-11,.prefix__cls-6{stroke-linecap:round;stroke-linejoin:round}.prefix__cls-8{fill:#74d5de}.prefix__cls-9{stroke:#74d5de;stroke-miterlimit:10;stroke-width:8.09px}.prefix__cls-10{fill:#fed385}.prefix__cls-11{stroke:#fd8369;stroke-width:3.24px}"
          }
        </style>
      </defs>
      <title>{"handshake-colour"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Vector">
          <path
            d="M696.32 129.32c72 68.12 134.95 132.62 205.93 224.31S1052 564.22 1014.66 635.2c-37.3 70.71-190.9 93.51-302.28 100-111.13 6.74-180.29-2.85-243-11.39-62.94-8.29-119.93-15.55-198.68-32.64-79-17.1-179.76-44-231-109-51-65-52.84-168.11-5.44-237S178 241.22 257.78 175.68C337.3 110.15 400.25 13.53 472 1.36c72-12.18 152.57 59.83 224.32 127.96z"
            fill="#f2f2f2"
          />
          <path
            d="M7.63 331.37C41.14 369 170.41 449 186.83 460.63S470.67 652.39 492.55 661c125.17-5.44 181.25-102.56 181.25-127.18S540.27 333.89 482.24 327.31c-10.36-1.17-131.29 36-131.29 36L84.09 137.51C51.84 111.14 7.63 76.42 7.63 76.42v255"
            fill="#d37c59"
          />
          <path
            transform="rotate(-30 783.114 424.355)"
            fill="#fed892"
            d="M750.74 288.4h64.74v271.92h-64.74z"
          />
          <circle cx={834.09} cy={512.57} r={17.8} fill="#fd8369" />
          <path
            className="prefix__cls-5"
            d="M693.46 333.82c-6.66 3.94-45.5 23.37-58.45 21.75s-71.22-22.66-89-16.19-102 43.7-102 43.7-76.07 80.92-77.68 82.55-.1 14.21 2.22 16.22 28.46 9.9 32.79 7.27 62.8-47.49 73.32-47.49 110.88 72.13 170.66 135.8c13.79-6 141.06-82.66 141.06-82.66zM290.9 527.93s-5.2 29.36 6.31 46.08 101.08 75.8 113.71 77.66 35.67-16 38.27-26-14.12-46.82-18.95-52-73.94-53.54-81.38-54.67-46.44 1.87-57.96 8.93z"
          />
          <path
            className="prefix__cls-6"
            d="M299.64 577.43l49.49-37.51M338.48 608.87l49.5-37.51M376.37 633.31l37.62-28.5M617.15 615.61L476.34 501.17M564.78 644.75l-89.31-72.58M520.13 660.21l-73.49-59.72"
          />
          <rect
            x={105.74}
            y={322.77}
            width={117.05}
            height={127.87}
            rx={23.74}
            ry={23.74}
            transform="rotate(40.53 164.258 386.659)"
            fill="#5b5b5b"
          />
          <path
            className="prefix__cls-8"
            transform="rotate(40.53 231.682 309.783)"
            d="M199.35 271.62h64.67v76.4h-64.67z"
          />
          <circle className="prefix__cls-9" cx={226.49} cy={654.69} r={20.04} />
          <circle className="prefix__cls-9" cx={737.68} cy={624.87} r={14.56} />
          <circle className="prefix__cls-9" cx={638.51} cy={286.38} r={14.56} />
          <path
            className="prefix__cls-10"
            d="M323.88 105.42h251.18v99.7H323.88z"
          />
          <path
            className="prefix__cls-10"
            d="M513.83 241.44l19.25-40.47h-38.5l19.25 40.47z"
          />
          <path
            className="prefix__cls-11"
            d="M357.99 130.8h182.95M357.99 155.27h182.95M357.99 179.73h182.95"
          />
          <path
            className="prefix__cls-8"
            d="M1035 115.61l-18.14 10.93s-208.38 126.05-273.7 163.89L851.9 478.76 1035 381.6z"
          />
        </g>
      </g>
    </svg>
  );
}

export default HandShake;
