import React from "react";
import HandShake from "./handShake";
// import Layout from "./layout";
// import Head from "./head";
import { contactList } from "../data/contactPage";
import "styles/contact.scss";

const ContactPage = () => {
  return (
    // <Layout>
    //   <Head title="Contact" />
    <div className="contacts-layout">
      <h1 className="contacts-title">Connect</h1>
      <HandShake className="contacts-image" />
      <div className="contacts-container">
        {contactList.map(each => (
          <a
            key={each.name}
            className="contacts-link"
            href={each.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {each.name.toUpperCase()}
          </a>
        ))}
      </div>
    </div>
    // </Layout>
  );
};

export default ContactPage;
