import React from "react";
import "styles/learning.scss";
import Fade from "react-reveal/Fade";
import { timeline, skillArray } from "../data/homePage";

const HorizontalProgress = ({ value }) => {
  let newWidth = value * 180;
  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${newWidth}px` }} />
    </div>
  );
};

const TimelineItem = ({ arr }) =>
  arr.map(each => (
    <div className="timeline-container" key={each.title}>
      <img src={each.icon} alt={each.title} width="80" />
      <div className="timeline-content">
        <span>{each.timeline}</span>
        <p>{each.title}</p>
      </div>
    </div>
  ));
const Learning = () => {
  return (
    <div className="poster-learn" id="learn">
      {/* <div>
        <h1>About Me</h1>
        <p>Web is something I'm fascinated about, now.</p>
        <p>
          Good Design fascinates me, as much as an Useful application of our knowledge!
        </p>
      </div> */}
      <div className="poster-learn-container">
        <Fade left cascade>
          <div>
            <h1 className="">Skills</h1>
            <div className="skill-each">
              <div className="skill-each-container">
                <h1 className="skill-each-title">Web</h1>
                <div className="skill">
                  {skillArray[0].map(each => (
                    <img
                      className="skill-logo"
                      key={each.name}
                      src={each.icon}
                      alt={each.name}
                    />
                  ))}
                </div>
              </div>
              <div className="skill-each-container">
                <h1 className="skill-each-title">Programming & Others</h1>
                <div className="skill">
                  {skillArray[1].map(each => (
                    <img
                      key={each.name}
                      className="skill-logo"
                      src={each.icon}
                      alt={each.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <h1>Experience & Education</h1>
            <TimelineItem arr={timeline} />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Learning;
