const buttonArray = [
  {
    title: "Contact Me",
    link: "/contact",
  },
  {
    title: "My Works",
    link: "/projects",
  },
  {
    title: "Have a Read",
    link: "/blogs",
  },
];
const timeline = [
  {
    title: "Front-End Web Developer (Head)",
    icon:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593283715/Arena/arena_ksjjvh.png",
    timeline: "July 2019 - ",
  },
  {
    title: "DSC Core Team Member",
    icon:
      "https://cdn-images-1.medium.com/max/1200/1*GUnXaV_rVzuce67X__7G6w.png",
    timeline: "August 2019 - ",
  },
  {
    title: "B.E (Electronics & Telecommunication Engineering)",
    icon:
      "https://upload.wikimedia.org/wikipedia/en/8/82/SIES_Graduate_School_of_Technology_logo.jpg",
    timeline: "August 2018 - July 2022",
  },
];
const skillArray = [
  [
    {
      name: "HTML5",
      icon: "https://img.icons8.com/color/48/000000/html-5.png",
      level: 0.9,
    },
    {
      name: "CSS3",
      icon: "https://img.icons8.com/color/48/000000/css3.png",
      level: 0.85,
    },
    {
      name: "JS",
      icon: "https://img.icons8.com/color/48/000000/javascript.png",
      level: 0.92,
    },
    {
      name: "ReactJS",
      icon: "https://img.icons8.com/color/48/000000/react-native.png",
      level: 0.75,
    },
    {
      name: "NodeJS",
      icon: "https://img.icons8.com/windows/48/00aa00/node-js.png",
      level: 0.85,
    },
    {
      name: "Sass",
      icon: "https://img.icons8.com/color/48/000000/sass.png",
      level: 0.72,
    },
    {
      name: "Bootstrap",
      icon: "https://img.icons8.com/color/48/000000/bootstrap.png",
      level: 0.75,
    },
  ],
  [
    {
      name: "C++",
      icon: "https://img.icons8.com/color/48/000000/c-plus-plus-logo.png",
      level: 0.7,
    },
    {
      name: "Python",
      icon: "https://img.icons8.com/color/48/000000/python.png",
      level: 0.79,
    },

    {
      name: "Github",
      icon: "https://img.icons8.com/fluent/48/000000/github.png",
      level: 0.85,
    },
  ],
];

module.exports = { buttonArray, timeline, skillArray };
