const contactList = [
  {
    name: "Github",
    link: "https://github.com/greykoalacode",
  },
  {
    name: "Medium",
    link: "https://medium.com/@kmaddy9122",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/surya-m-312b05148/",
  },
  {
    name: "FreeCodeCamp",
    link: "https://www.freecodecamp.org/surya-madhavan",
  },
  {
    name: "CodePen",
    link: "https://codepen.io/greykoalacode",
  },
  {
    name: "Mail",
    link: "mailto:kmaddy9122@gmail.com",
  },
];

module.exports = { contactList };
