import React, { useRef, useEffect } from "react";
import Fade from "react-reveal/Fade";
import Layout from "../components/layout";
import "styles/index.scss";
import Head from "../components/head";
import poster from "../../static/assets/poster.jpg";
import { TweenMax, Power3 } from "gsap";
import Learning from "../components/learning";
// import EndPoster from "../components/endPoster";
import ContactPage from "../components/contact";

const HomePage = () => {
  let logoItem = useRef(null);
  useEffect(() => {
    TweenMax.to(logoItem, 1, {
      opacity: 1,
      y: -10,
      // x: -10,
      ease: Power3.easeInOut,
      delay: 0.2,
    });
  }, []);
  return (
    <Layout>
      <Head title="Home" />
      <div className="poster-intro">
        <Fade left>
          <div>
            <h1 className="poster-title">Hello, I'm Surya</h1>
            <h3>Learn to Solve • Curious </h3>
            <p>
              I'm a self-taught (not totally, :p) programmer who loves to solve
              problems using technology.
            </p>
          </div>
        </Fade>
        {/* <Fade right> */}
        <img
          ref={el => {
            logoItem = el;
          }}
          className="poster-image"
          alt="poster"
          src={poster}
        />
        {/* </Fade> */}
        <a href="#learn">
          <div className="arrow bounce">
            <span className="material-icons">keyboard_arrow_down</span>
          </div>
        </a>
      </div>
      <Learning />
      {/* <EndPoster /> */}
      <ContactPage />
    </Layout>
  );
};

export default HomePage;
